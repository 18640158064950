import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import logo from '../images/logo/logo.png';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#f0f0f0' },
    secondary: { main: '#aaaaaa' },
    background: { main: '#424242' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    "fontSize": 20,
    "fontWeightMedium": 900
  }
});

const styles  = {
  footerBarContainer: {
    width: '100%',
    height: '60px',
    backgroundColor: '#6d6d6d',
  },
  footerBar: {
    width: '1200px',
    height: '100%',
    margin: 'auto',
    backgroundColor: '#6d6d6d',
    boxShadow: '0 0px 0px 0px',
  },
  grow: {
    flexGrow: 1,
  },
  logoButton: {
    marginRight: 20,
  },
  textButton: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 12,
    padding: '6px 12px',
    border: '0px solid',
    backgroundColor: '#6d6d6d',
    borderColor: '#6d6d6d',
    fontFamily: [
      'sans-serif',
    ].join(','),
    '&:hover': {
      backgroundColor: '#6d6d6d',
      borderColor: '#6d6d6d',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#6d6d6d',
      borderColor: '#6d6d6d',
    },
    '&:focus': {
      boxShadow: '0 0 0 0 rgba(0,0,0,0)',
    },
  },
};

function FooterBar(props) {
  const { classes } = props;
  return (
    <div className={classes.footerBarContainer}>
      <MuiThemeProvider theme={theme}>
        <AppBar style={styles.footerBar} position="static">
        <Toolbar>
          <Link style={{ textDecoration: 'none' }} to='/company'>
            <Button
              color="primary"
              disableRipple
              className={classNames(classes.textButton)}
            >
              <img className={classes.logoButton} src={logo} alt="Logo" height="32" width="154" />
            </Button>
          </Link>
          <div className={classes.grow} />
          <Link style={{ textDecoration: 'none' }} to='/company'>
            <Button
              color="primary"
              disableRipple
              className={classNames(classes.textButton)}
            >
              About
            </Button>
          </Link>
          <Link style={{ textDecoration: 'none' }} to='/termofuse'>
            <Button
              color="primary"
              disableRipple
              className={classNames(classes.textButton)}
            >
              Terms of Use
            </Button>
          </Link>
          <Link style={{ textDecoration: 'none' }} to='/privacy'>
            <Button
              color="primary"
              disableRipple
              className={classNames(classes.textButton)}
            >
              Privacy
            </Button>
          </Link>
          <Link style={{ textDecoration: 'none' }} to='/sitemaps'>
            <Button
              color="primary"
              disableRipple
              className={classNames(classes.textButton)}
            >
              Sitemaps
            </Button>
          </Link>
        </Toolbar>
        </AppBar>
      </MuiThemeProvider>
    </div>
  );
}

FooterBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FooterBar);
