import React from 'react';
import 'react-sticky-header/styles.css';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import './colors.css';
var appImages = require.context('../images/config/app', true);
var deivceImages = require.context('../images/config/device', true);

const theme = createMuiTheme({
  palette: {
    primary: { main: '#FFAA5E' },
    secondary: { main: '#f0f0f0' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "Arial",
    "fontSize": 18,
    "fontWeightRegular": 300,
    "fontWeightMedium": 400,
  },
  stepper: {
      iconColor: '#aa0000',
  },
  overrides: {
    MuiStepLabel: {
      label: {
        color: '#f0f0f0',
        '&$active': {
          color: '#FFAA5E',
        },
      },
    },
  },
});

const styles = theme => ({
  backgroundContainer: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    color: '#f0f0f0',
		backgroundColor: '#424242',
  },
  contentContainer: {
    width: "100%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  text: {
    width: '600px',
    color: '#f0f0f0',
    marginLeft: '150px',
  },
  root: {
    background: '#424242',
    borderRadius: 0,
    border: 0,
    boxShadow: '0 0 0 0 rgba(255, 105, 135, .3)',
  },
  step: {
    color: "#aaaaaa",
    '&:completed': {
      color: "#aa0000"
    },
    '&:active': {
      color: "#aa0000"
    },
    '&:disabled': {
      color: "#aa0000"
    }
  },
  active: {
  }, //needed so that the &$active tag works
  completed: {
  },
  disabled: {
  },
  labelContainer: {
    color: "#f0f0f0",
  },
});

const appIamgeStyle = {
  container: {
    width: 260,
    height: 235,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  appContainer: {
    width: 129,
    height: 235,
    display: 'flex',
    flexDirection: 'column',
  },
  iconContainer: {
    width: 129,
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  gap: {
    width: 5,
    height: 230,
  },
}

function getZoomAppImage(image, pos) {
  if (image === undefined) {
    return <div style={appIamgeStyle.appContainer} />;
  }
  return  <Popup
            trigger={
              <img
                style={{ border: '2px solid #aaaaaa'}}
                src={image}
                alt="config"
                height="200"
                width="125"/>
            }
            on="hover"
            position={pos}
            arrow={false}
            closeOnDocumentClick
            offsetY = '100px'
            contentStyle = {{
              width: '317px',
              height:'500px',
              padding: 0,
              border: '0px solid #424242',
            }}
          >
            <img
              style={{ border: '2px solid #aaaaaa'}}
              src={image}
              alt='config'
              height='500px'
              width='313px'
            />
          </Popup>;
}

function getPlatformImages(androidImage, iPhoneImage) {
  let android_logo = appImages(`./android/android-logo.png`);
  let apple_logo = appImages(`./iphone/apple-logo.png`);
  return (
    <div style={appIamgeStyle.container} >
      <div style={appIamgeStyle.appContainer} >
        {getZoomAppImage(androidImage, "left center")}
        <div style={{height: '3px'}} />
        <div style={appIamgeStyle.iconContainer} >
          <img src={android_logo} alt='Android Logo' height='32px' width='32px' />
        </div>
      </div>
      <div style={appIamgeStyle.gap} />
      <div style={appIamgeStyle.appContainer} >
        {getZoomAppImage(iPhoneImage, "right center")}
        <div style={{height: '3px'}} />
        <div style={appIamgeStyle.iconContainer} >
          {(iPhoneImage !== undefined)? <img src={apple_logo} alt='Apple Logo' height='32px' width='32px' /> : null}
        </div>
      </div>
    </div>
  );
}

function getAppImages(image) {
  let android_logo = appImages(`./android/android-logo.png`);
  let apple_logo = appImages(`./iphone/apple-logo.png`);
  return (
    <div style={appIamgeStyle.container} >
      <div style={appIamgeStyle.appContainer} >
        {getZoomAppImage(image, "left center")}
        <div style={{height: '3px'}} />
        <div style={appIamgeStyle.iconContainer} >
          <img src={android_logo} alt='Android Logo' height='32px' width='32px' />
          <div style={{width: '30px'}} />
          <img src={apple_logo} alt='Apple Logo' height='32px' width='32px' />
        </div>
      </div>
    </div>
  );
}

function getDeviceImages(image) {
  return (
    <div style={appIamgeStyle.container} >
      <Popup
        trigger={
          <img
            style={{ border: '2px solid #aaaaaa'}}
            src={image}
            alt="config"
            height="231"
            width="256"/>
        }
        on="hover"
        position="right center"
        arrow={false}
        closeOnDocumentClick
        offsetY = '100px'
        contentStyle = {{
          width: '317px',
          height:'500px',
          padding: 0,
          border: '0px solid #424242',
        }}
      >
        <img
          style={{ border: '2px solid #aaaaaa'}}
          src={image}
          alt="config"
          height="500"
          width="554"/>
      </Popup>
    </div>
  );
}

function getStepImage(step) {
  switch (step) {
    case 0:
      return null;
    case 1:
      return getDeviceImages(deivceImages(`./device1.png`));
    case 2:
      return getDeviceImages(deivceImages(`./device2.png`));
    case 3:
      return getDeviceImages(deivceImages(`./device3.png`));
    case 4:
      return getPlatformImages(appImages(`./android/conf-app0.png`), appImages(`./iphone/conf-app0.png`));
    case 5:
      return getAppImages(appImages(`./android/conf-app1.png`));
    case 6:
      return getAppImages(appImages(`./android/conf-app2.png`));
    case 7:
      return getPlatformImages(appImages(`./android/conf-app3.png`), appImages(`./iphone/conf-app3.png`));
    case 8:
      return getPlatformImages(appImages(`./android/conf-app4.png`), appImages(`./iphone/conf-app4.png`));
    case 9:
      return getPlatformImages(appImages(`./android/conf-app5.png`), appImages(`./iphone/conf-app5.png`));
    case 10:
      return getPlatformImages(appImages(`./android/conf-app6.png`));
    case 11:
      return getAppImages(appImages(`./android/conf-app7.png`));
    case 12:
      return getAppImages(appImages(`./iphone/conf-app8.png`));
    case 13:
      return getDeviceImages(deivceImages(`./device2.png`));
    default:
      return null;
  }
}

function getSteps() {
  return [
    'Important Message',
    'Device Introductions',
    'Turn On Device',
    'Check LED Light',
    'Pick Secured Home WiFi',
    'Launch MoniThing App',
    'Add Device',
    'Enter WiFi Password',
    'Find Device WiFi',
    'Connect to Device',
    'Android Only Step',
    'Enter Device Name',
    'Wait Completion',
    'Verify Device',
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return  (
                <div style={{width: '860px', }}>
                  The MoniThing device can be in one of two states, <b>Factory
                  Setting</b> state and <b>User Configured</b> state. The starting
                  point of these instructions is from the <b>Factory Setting</b>
                  state. If your device has been configured and is in the <b>User
                  Configured</b> state, please reset it back to <b>Factory Setting
                  </b> state by following the reset instructions in{' '}
                  <Link style={{ textDecoration: 'none', color: 'lightgreen' }} to='/faq'>
                    FAQ
                  </Link>
                  {' '}page.
                </div>
              );
    case 1:
      return  (
                <div style={{width: '600px', }}>
                  The MoniThing device is designed to be a very simple operable
                  device. It only has one button which is placed behind the pin
                  hole as you can see in the picture. It also has a LED light as
                  the indicator. The USB port is used to charge the rechargeable
                  battery when needed.
                </div>
              );
    case 2:
      return  (
                <div style={{width: '600px', }}>
                  Turning on the MoniThing device is very simple. Just gently push
                  a paperclip into the pin hole and hold for about one second.
                  If the red LED indicator lights up and stays on, the device is
                  ready for configuration.
                </div>
              );
    case 3:
      return  (
                <div style={{width: '600px', }}>
                  You have about 10 minutes to configure the MoniThing device
                  before it turns off. This is done to conserve battery. Of
                  course, you can turn it on again if you are unable to finish
                  it within 10 minutes.
                </div>
              );
    case 4:
      return  (
                <div style={{width: '600px', }}>
                  For the whole solution to work, MoniThing device needs to be
                  connected to your secured home WiFi. The device will use the
                  same WiFi that your phone is using. Please go to the WiFi
                  settings on your phone and make sure it is connected to the
                  WiFi you want your device to connect to.
                </div>
              );
    case 5:
      return  (
                <div style={{width: '600px', }}>
                  After you launch the MoniThing app, you should have the "+"
                  button on the Home screen. Press it to start the adding device
                  process.
                </div>
              );
    case 6:
      return  (
                <div style={{width: '600px', }}>
                  Your Wifi name should appear on the screen. Please make sure
                  it is the WiFi you want the device to connect to. Enter your
                  home WiFi password and confirm it again on the screen. Press
                  the "Next" button on the top right corner to continue.
                </div>
              );
    case 7:
      return  (
                <div style={{width: '600px', }}>
                  Every MoniThing device has a unique WiFi Id which starts with
                  "AHM". Please go to the WIfi settings on your phone to find
                  the device.<br/><br/>
                  There is an important message for Android users only. We will
                  explain it in Steps 10 and 11.
                </div>
              );
    case 8:
      return  (
                <div style={{width: '600px', }}>
                  Connect to the device that you would like to configure by
                  selecting it.
                </div>
              );
    case 9:
      return  (
                <div style={{width: '600px', }}>
                  If you are an Android phone user, please wait a moment until
                  a message box shows up at the top of the screen. Once you see
                  it, please tap the box.<br/><br/>
                  If you are an iPhone user, please wait until you see a green
                  check in front of the device name. It is OK to see "Unsecured
                  Network" because this connection is going to be short-lived
                  and short-distance. Switch back to MoniThing app.
                </div>
              );
    case 10:
      return  (
                <div style={{width: '600px', }}>
                  If you are an iPhone user, please ignore this step and go to
                  next step directly.<br/><br/>
                  Please confirm by clicking "Yes". This message box explains that
                  the connection of the device is unsecured. It is OK because this
                  connection is going to be short-lived and short-distance. Switch
                  back to MoniThing app.
                </div>
              );
    case 11:
      return  (
                <div style={{width: '600px', }}>
                  Your phone has connected to a MoniThing device. Once you are
                  back on the MoniThing app, you can give a name to the device.
                  Click the "Submit" button when you are ready. The app sends
                  the WiFi connection information and the device name to the
                  device. It adds itself to the MoniThing Cloud Services.
                </div>
              );
    case 12:
      return  (
                <div style={{width: '600px', }}>
                  If the device is configured successfully, a message box will
                  show up at the top of the screen and a new gray circle with
                  the device name will appear on the Home screen. The gray color
                  means there is no activity from the toilet for last 24 hours.
                </div>
              );
    case 13:
      return  (
                <div style={{width: '600px', }}>
                  You can also verify that the device is configured successfully
                  or not through the device itself. Use a paperclip to push the
                  button in the pin hole and pay attention to the LED light. If
                  the LED turns off after you release the button, the device has
                  been configured successfully. If the LED stays on, you need to
                  start over again.
                </div>
              );
    default:
      return  (
                <div style={{width: '600px', paddingRight: '20px', }}>
                  Unknown step
                </div>
              );
  }
}

class InstallationInstructions extends React.Component {
  render() {
    const { classes } = this.props;
    const steps = getSteps();

    return (
      <div className={classes.backgroundContainer}>
        <MuiThemeProvider theme={theme}>
          <Stepper classes={{ root: classes.root }} orientation="vertical">
            {steps.map((label, index) => {
              const labelProps = {};
              labelProps.icon = <div style={{ backgroundColor: 'green', color: 'white', width: '15px', padding: '2px', textAlign: 'center', height: '15px', fontSize: '10px', borderRadius: '50%' }}>{index}</div>
              // let img_src = null;
              // if (index > 0 && index < steps.length - 1) {
              //   img_src = images(`./install${index}.png`);
              // }
              return (
                <Step key={label} active={true} >
                  <StepLabel
                    classes={{
                      label: classes.labelContainer,
                      completed: classes.labelContainer,
                      active: classes.labelContainer,
                    }}
                    StepIconProps={{
                      classes: {
                        root: classes.step,
                        completed: classes.completed,
                        active: classes.active,
                        disabled: classes.disabled
                      }
                    }}
                  >
                    {label}
                  </StepLabel>
                  <StepContent>
                    <div className={classes.contentContainer} >
                      {getStepImage(index)}
                      <div style={{width: '20px', }} />
                      <Typography
                        className={styles.text}
                        gutterBottom
                        variant="subtitle2"
                        component="subtitle2"
                        color="secondary"
                        align="justify"
                      >
                        {getStepContent(index)}
                      </Typography>
                    </div>
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
        </MuiThemeProvider>
        <div style={{ width: '100%', height: '300px'}} />
      </div>
    );
  }
}

InstallationInstructions.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(InstallationInstructions);
