import React from 'react';
import 'react-sticky-header/styles.css';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';
import './colors.css';
var images = require.context('../images/app', true);

const theme = createMuiTheme({
  palette: {
    primary: { main: '#FFAA5E' },
    secondary: { main: '#f0f0f0' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "Arial",
    "fontSize": 18,
    "fontWeightRegular": 300,
    "fontWeightMedium": 400,
  },
  stepper: {
      iconColor: '#aa0000',
  },
  overrides: {
    MuiStepLabel: {
      label: {
        color: '#f0f0f0',
        '&$active': {
          color: '#FFAA5E',
        },
      },
    },
  },
});

const styles = theme => ({
  backgroundContainer: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    color: '#f0f0f0',
		backgroundColor: '#424242',
  },
  contentContainer: {
    width: "100%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  textContainer: {
    width: '600px',
    paddingLeft: '20px',
  },
  text: {
    width: '300px',
    color: '#f0f0f0',
    marginLeft: '30px',
    marginRight: '40px',
  },
  root: {
    background: '#424242',
    borderRadius: 0,
    border: 0,
    boxShadow: '0 0 0 0 rgba(255, 105, 135, .3)',
  },
  step: {
    color: "#aaaaaa",
    '&:completed': {
      color: "#aa0000"
    },
    '&:active': {
      color: "#aa0000"
    },
    '&:disabled': {
      color: "#aa0000"
    }
  },
  active: {
  }, //needed so that the &$active tag works
  completed: {
  },
  disabled: {
  },
  labelContainer: {
    color: "#f0f0f0",
  },
});

function getSteps() {
  return [
    'Introduction',
    'Login',
    'Sign Up (Step One)',
    'Sign Up (Step Two)',
    'Phone Number Validation',
    'Email Address Validation',
    'New User Home',
    'Home',
    'Device List',
    'Device Detail',
    'Alert List',
    'Profile',
    'Menu',
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return  <div>
                MoniThing app is the companion app of the MoniThing device. This
                app is required to configure and manage the devices. It is also
                the platform for users to understand their toilet water usage,
                receive abnormal water flow alerts, and stay up to date with the
                water saving tips.
              </div>;
    case 1:
      return  <div>
                In order to protect your privacy, MoniThing requires the users
                login before using the app. Please click "Signup" at the bottom
                to register a new user if you are the first time user.
              </div>;
    case 2:
      return  <div>
                There is some personal information required to make this whole
                solution work. On this screen, please provide your name, phone
                number, and zip code. Please enter a valid phone number because
                a text message with a validation code will be sent to it.
              </div>;
    case 3:
      return  <div>
                Your email address is used as the user login name. You must enter
                it along with your password twice to reduce the possibility of
                errors.
                <br/><br/>
                Please use at least 8 characters for your password. We recommend
                your password has the combination of uppercase, lowercase, digit,
                and symbol characters. The color progress bar under the password
                indicates the strength of your password.
              </div>;
    case 4:
      return  <div>
                A valid phone number is critical for delivering the alert text
                messages. After your new user is registered, a text message with
                a validation code will be sent to your phone. Please enter the
                code and click "Verify" button to complete the phone number
                validation.
                <br/><br/>
                If you do not receive the text message, please check the phone
                number on the screen to make sure it is correct before clicking
                "Resend" button. You can enter a different phone number by
                clicking "Phone" at the bottom.
              </div>;
    case 5:
      return  <div>
                An email with a validation code has also been sent to your email
                address. Please enter the code and click "Verify" to complete
                your email address validation. Please give the email some time
                to be sent as the process may be a bit slow. There is a also a
                possibility that the email may be sent to your junk folder.
                <br/><br/>
                If you gave a wrong email address when you created your user,
                please log out and start over again.
              </div>;
    case 6:
      return  <div>
                Congratulations! You have just registered your user successfully.
                Since you haven't added any MoniThing devices yet, you only have
                a big fat "+" button in the middle of your home screen and another
                much smaller "+" button at the top right corner. Both buttons are
                used to add a MoniThing device to your account.
                <br/><br/>
                Please click{' '}
                <Link style={{ textDecoration: 'none', color: 'lightgreen' }} to='/device/configuration'>
                  "Device Configuration Instructions"
                </Link>
                {' '} and follow the instructions to add a device.
              </div>;
    case 7:
      return  <div>
                After MoniThing devices are added, the MoniThing app will start
                to show their data on the Home screen. The main graphs have two
                dimensions of information. The size of each circle indicates the
                water usage from your toilets within the last 24 hours. The color
                of each circle indicates the individual toilet status within last
                24 hours. The green color means the toilet is in a good state.
                The red color means there is an abnormal water flow detected
                within last 24 hours.
              </div>;
    case 8:
      return  <div>
                The Device list has all active devices on your account. You can
                click the device name to go the device detail screen. The graph
                under the device name shows the timing and water usage of every
                flush from each individual toilet.
              </div>;
    case 9:
      return  <div>
                The Device Detail screen shows more interesting graphs such as
                "Per Flush Water Usage" and "Daily Water Usage".
                <br/><br/>
                There is an "Edit" button at the top right corner. Click it to
                modify the device name and description.
              </div>;
    case 10:
      return  <div>
                The Alert list shows alerts from the last 7 days by default. You
                can pick a different date range from the drop down menu at the
                top toolbar.
              </div>;
    case 11:
      return  <div>
                The Profile has your personal information. You can reset your
                password or change your phone number on this screen. If you
                change your phone number, you will be asked to validate it again.
              </div>;
    case 12:
      return  <div>
                You can access the app menu by clicking the "Menu" icon at the
                top right corner or swiping your finger from left to right. You
                can use the menu to logout, navigate to different screens, or
                check out the legal documents, etc...
              </div>;
    default:
      return  <div>
                Unknown step
              </div>;
  }
}

class AppIntroduction extends React.Component {
  render() {
    const { classes } = this.props;
    const steps = getSteps();

    return (
      <div className={classes.backgroundContainer}>
        <MuiThemeProvider theme={theme}>
          <Stepper classes={{ root: classes.root }} orientation="vertical">
            {steps.map((label, index) => {
              const labelProps = {};
              labelProps.icon =
                <div style={{
                  backgroundColor: 'green',
                  color: 'white',
                  width: '15px',
                  padding: '2px',
                  textAlign: 'center',
                  height: '15px',
                  fontSize: '10px',
                  borderRadius: '50%'
                }}>
                  {index}
                </div>
              let img_src = images(`./app${index}.png`);
              return (
                <Step key={label} active={true} >
                  <StepLabel
                    classes={{
                      label: classes.labelContainer,
                      completed: classes.labelContainer,
                      active: classes.labelContainer,
                    }}
                    StepIconProps={{
                      classes: {
                        root: classes.step,
                        completed: classes.completed,
                        active: classes.active,
                        disabled: classes.disabled
                      }
                    }}
                  >
                    {label}
                  </StepLabel>
                  <StepContent>
                    <div className={classes.contentContainer} >
                      <Popup
                        trigger={
                          <img
                            key = {index}
                            style={{ border: '2px solid #aaaaaa'}}
                            src={img_src} alt="App Introduction"
                            height="200px"
                            width="125px"
                          />
                        }
                        on="hover"
                        position="left center"
                        arrow={false}
                        closeOnDocumentClick
                        offsetY = '100px'
                        contentStyle = {{
                          width: '317px',
                          height:'500px',
                          padding: 0,
                          border: '0px solid #424242',
                        }}
                      >
                        <img
                          key = {index}
                          style={{ border: '2px solid #aaaaaa'}}
                          src={img_src}
                          alt='App Introduction'
                          height='500px'
                          width='313px'
                        />
                      </Popup>
                      <div style={{width: '600px', marginLeft: '30px'}} >
                        <Typography
                          className={styles.text}
                          gutterBottom
                          variant="subtitle2"
                          component="subtitle2"
                          color="secondary"
                          align="justify"
                        >
                          {getStepContent(index)}
                        </Typography>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
        </MuiThemeProvider>
        <div style={{ width: '100%', height: '300px'}} />
      </div>
    );
  }
}

AppIntroduction.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(AppIntroduction);
