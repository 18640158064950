import React from 'react';
import 'react-sticky-header/styles.css';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import HeaderBar from '../tools/HeaderBar';
import FooterBar from '../tools/FooterBar';
import { getMixpanel } from '../tools/MixpanelHelper';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#f0f0f0' },
    secondary: { main: '#0EFF04' },
    background: { main: '#420000' },
    textPrimary: { main: '#aa0000' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "Arial",
    "fontSize": 16,
    "fontWeightMedium": 900
  }
});

const styles = {
  screenContainer: {
    width: '100wv',
    backgroundColor: '#424242',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
  card: {
    width: '800px',
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#424242',
    boxShadow: '0 0px 0px 0px',
    margin: 'auto',
  },
  media: {
    width: '375px',
    height: '250px',
    justifyContent: 'center',
    backgroundColor: '#f0f0f0',
    padding: 0,
  },
  aboutContainer: {
    width: '100%',
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
  },
  aboutInnerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 'auto',
  },
  topicContainer: {
    width: '770px',
    height: '100%',
    grow: 1,
    flexDirection: 'column',
    justifyContent: 'left',
  },
  text: {
    width: '100%',
    margin: 'auto',
  },
};

class Company extends React.Component {
  componentWillMount() {
    let mixpanel = getMixpanel();
    mixpanel.track("Company Page.");
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.screenContainer}>
        <Helmet>
            <title>About MoniThing</title>
            <meta name="description" content="About MoniThing Company" />
        </Helmet>
        <HeaderBar/>
        <MuiThemeProvider theme={theme}>
          <Card className={classes.card} raised='false'>
            <div className={classes.aboutContainer}>
              <div className={classes.aboutInnerContainer}>
                <div style={{width: '50px'}} />
                <Typography className={classes.text} variant="h7" component="h1" color="secondary" align="center">
                  About MoniThing
                </Typography>
              </div>
            </div>
            <div className={classes.topicContainer}>
              <Typography className={classes.text} variant="body1" color="primary" align="left">
                Remember the 2016 California drought?
              </Typography>
            </div>
            <div style={{height: '20px'}} />
            <div style={{width: '770px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }} >
              <div style={{display: 'flex', flexDirection: 'column', }} >
                <CardMedia
                  className={classes.media}
                  image={require('../images/company/drought-before.png')}
                  title="Before 2016 Dought"
                />
                <div style={{height: '10px'}} />
                <Typography className={classes.text} variant="h7" component="h5"  color="primary" align="center">
                  Before 2016 California Drought
                </Typography>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', }} >
                <CardMedia
                  className={classes.media}
                  image={require('../images/company/drought-after.png')}
                  title="2016 Dought"
                />
                <div style={{height: '10px'}} />
                <Typography className={classes.text} variant="h7" component="h5"  color="primary" align="center">
                  During 2016 California Drought
                </Typography>
              </div>
            </div>
            <div style={{height: '20px'}} />
            <div className={classes.topicContainer}>
              <Typography className={classes.text}  variant="body1"  color="primary" align="justify">
                <p>
                  It was one of the worst droughts in California history.
                  Unfortunately, it will not be the last. Climate change has
                  become a reality. The fresh water shortage around the globe
                  will stay with us for a long time coming.
                </p>
                <p>
                  One day, near the end of 2016, the founder of MoniThing walked
                  by one of the bathrooms in his house and heard the toilet had
                  running water. He felt defeated and guilty since he put so much
                  effort into reducing water usage during the drought. Nobody
                  noticed when it started and how long it had been running. He
                  went online and hoped to find a product which would solve this
                  problem, but he was not satisfied with the products on the
                  market. He believed the product had to be easy to install and
                  use and had to be able to integrate with modern lifestyle. He
                  thought to himself, "Let me solve this problem!" After a year
                  and half, a new IoT device is being put to use and is keeping
                  eyes on every toilet flush.
                </p>
                <p>
                  We work hard every day to inspire many people to reduce water
                  waste and protect our environment through our innovations and
                  with the help of modern technologies.
                </p>
              </Typography>
            </div>
            <div style={{ width: '100%', height: '100px'}} />
          </Card>
        </MuiThemeProvider>
        <FooterBar/>
      </div>
    );
  }
}

Company.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Company);
