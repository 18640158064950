var mixpanel = require('mixpanel-browser');
var productionHost = 'monithing.com';
var devToken = 'ee0ab07ebfb53749a853a09d439639e4';
var prodToken = '735ffb21b5981403624719ca1f30d3ba';
//If the hostname is anything other than your production domain, initialize the Mixpanel library with your Development Token
if (window.location.hostname.toLowerCase().search(productionHost) < 0) {
    mixpanel.init(devToken);
} else {
    mixpanel.init(prodToken);
}

export function getMixpanel() {
  return mixpanel;
}
