import React from 'react';
import 'react-sticky-header/styles.css';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { spring } from 'react-motion';
import HeaderBar from '../tools/HeaderBar';
import FooterBar from '../tools/FooterBar';
import { LoopMotion } from '../tools/LoopMotion';
import DrawFlow from '../tools/DrawFlow';
import PricePackage from '../price/PricePackage';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#f0f0f0' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "Arial",
    "fontSize": 16,
    "fontWeightMedium": 900
  },
});

const styles = {
  screenContainer: {
    width: '100wv',
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    borderRadius: 0,
    backgroundColor: '#424242',
    boxShadow: '0 0px 0px 0px',
    margin: 'auto',
  },
  titleContainer: {
    width: '100%',
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
  },
  titleInnerContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 'auto',
  },
  media: {
    width: '475px',
    height: '400px',
    justifyContent: 'center',
    backgroundColor: '#424242',
    padding: 0,
  },
  topicContainer: {
    width: '770px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  text: {
    width: '100%',
    margin: 'auto',
  },
};

class Device extends React.Component {
  constructor(props) {
    super(props);
    this.handleAnimationEnd = this.handleAnimationEnd.bind(this);

    this.state = {
      flag: true,
      step: 0,
    };
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.raf);
  }

  handleAnimationEnd() {
    if (this.state.step !== 2) {
      this.setState({
        step: this.state.step + 1,
      });
    } else {
      this.setState({
        step: 0,
      });
    }
  }

  render() {
    const { classes } = this.props;
    let desc =  "The smart MoniThing device detects the toilet running water " +
                "and helps people to reduce the water waste and protects our " +
                "environment.";

    return (
      <div className={classes.screenContainer}>
        <Helmet>
            <title>MoniThing Device</title>
            <meta name="description" content = {desc} />
        </Helmet>
        <HeaderBar/>
        <MuiThemeProvider theme={theme}>
          <Card className={classes.card} raised='false'>
            <div className={classes.titleContainer}>
              <div className={classes.titleInnerContainer}>
                <Typography
                  className={classes.text}
                  variant="h4"
                  component="h1"
                  color="primary"
                  align="center">
                  What makes a MoniThing deivce?
                </Typography>
              </div>
            </div>
            <div style={{
                width: '770px',
                display: 'flex',
                flexDirection: 'row',
                margin: 'auto',
              }} >
              <div style={{
                display: 'flex', flexDirection: 'column', margin: 'auto',
              }} >
                <div style={{display: 'flex', flexDirection: 'row', }} >
                  <CardMedia
                    className={classes.media}
                    image={require('../images/product/product.png')}
                    title="MoniThing Device"
                  />
                  <div style={{width: '20px'}} />
                  <PricePackage />
                </div>
              </div>
            </div>
            <div style={{height: '20px'}} />
          </Card>
          <Card className={classes.card} raised='false'>
            <div style={{
              width: '770px', display: 'flex', flexDirection: 'row', margin: 'auto',
            }} >
              <div className={classes.topicContainer}>
                <Typography component={'span'} className={classes.text} variant="body1" color="primary" align="justify">
                  MoniThing device is a smart device which keeps eyes on the water
                  usage of your toilet. Here are some highlights of the device
                  features.
                  <ul type="square">
                    <li>
                        Uses the machine learning to build the profile for each individual
                        toilet
                    </li>
                    <li>
                      Tracks daily water usage for every toilet.
                    </li>
                    <li>
                      Detects and alerts users of any abnormal water flow compared to the
                      individual usage pattern
                    </li>
                    <li>
                      Long lasting battery power by a clever long lasting power solution
                    </li>
                    <li>
                      Provides water-saving tips based on the large community-based data
                    </li>
                  </ul>
                </Typography>
              </div>
            </div>
            <div style={{ width: '100%', height: '50px'}} />
          </Card>
          <Card className={classes.card} raised='false'>
            <CardMedia
              style={{
                width: '800px',
                height: '516px',
                justifyContent: 'center',
                backgroundColor: '#424242',
                margin: 'auto',
              }}
              image={require('../images/product/data_flow.png')}
              title=""
            >
              <LoopMotion
                styleFrom={{x: 0}}
                styleTo={{x: spring(1, {precision: 1, })}}
                onRestCallback={this.handleAnimationEnd}
              >
                {interpolatingStyle => (<DrawFlow img = {this.state.step} />)}
              </LoopMotion>
            </CardMedia>
            <div style={{ width: '100%', height: '100px'}} />
          </Card>
        </MuiThemeProvider>
        <FooterBar/>
      </div>
    );
  }
}

Device.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Device);
