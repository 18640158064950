import React from 'react';
import 'react-sticky-header/styles.css';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import  { Link } from 'react-router-dom';
import HeaderBar from '../tools/HeaderBar';
import FooterBar from '../tools/FooterBar';
import { getMixpanel } from '../tools/MixpanelHelper';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#f0f0f0' },
    secondary: { main: '#FFAA5E' },
    background: { main: '#420000' },
    textPrimary: { main: '#FFAA5E' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "Arial",
    "fontSize": 16,
    "fontWeightMedium": 900
  }
});

const styles = {
  screenContainer: {
    width: "100wv",
    backgroundColor: '#424242',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contentContainer: {
    width: "100%",
    height: "100%",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
  },
  card: {
    width: '700px',
    height: '100%',
    borderRadius: 0,
    backgroundColor: '#424242',
    boxShadow: '0 0px 0px 0px',
  },
  topicContainer: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'left',
    margin: 'auto',
    paddingLeft: '30px',
  },
  indexContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    marginLeft: '20px',
  },
  linkContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    marginLeft: '50px',
  },
  indexText: {
    width: '50px',
  },
  text: {
    width: '100%',
    flexDirection: 'row',
    paddingLeft: '20px',
  },
};

class Sitemaps extends React.Component {
  componentWillMount() {
    let mixpanel = getMixpanel();
    mixpanel.track("FAQ Page.");
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.screenContainer}>
        <Helmet>
            <title>MoniThing Sitemaps</title>
            <meta name='description' content = 'MoniThing Sitemaps' />
            <meta name='robots' content = 'noindex,nofollow' />
        </Helmet>
        <HeaderBar/>
        <div style={{display: 'flex', flexDirection: 'row', margin: 'auto', }} >
        <div className={classes.contentContainer}>
          <MuiThemeProvider theme={theme}>
            <Card className={classes.card} raised='false'>
              <div className={classes.topicContainer}>
                <div style={{ height: '20px' }} />
                <Typography className={classes.text} gutterBottom variant="h6" component="h1" color="primary" align="left">
                  Sitemaps
                </Typography>
                <div style={{ height: '20px' }} />
              </div>
            </Card>
            <Card className={classes.card} raised='false'>
              <div className={classes.topicContainer}>
                <div style={{ height: '20px' }} />
                <div className={classes.indexContainer}>
                  <Typography className={classes.indexText} gutterBottom variant="h5" component="h5" color="secondary" align="left">
                    A
                  </Typography>
                </div>
                <div className={classes.linkContainer}>
                  <Link style={{ textDecoration: 'none', color: '#f0f0f0' }} to='/company'>
                    <Typography className={classes.text} gutterBottom variant="subtitle2" component="h1" color="primary" align="left">
                      About MoniThing
                    </Typography>
                  </Link>
                </div>
                <div style={{ height: '10px' }} />
              </div>
            </Card>
            <Card className={classes.card} raised='false'>
              <div className={classes.topicContainer}>
                <div style={{ height: '20px' }} />
                <div className={classes.indexContainer}>
                  <Typography className={classes.indexText} gutterBottom variant="h5" component="h5" color="secondary" align="left">
                    D
                  </Typography>
                </div>
                <div className={classes.linkContainer}>
                  <Link style={{ textDecoration: 'none', color: '#f0f0f0' }} to='/device/configuration'>
                    <Typography className={classes.text} gutterBottom variant="subtitle2" component="h1" color="primary" align="left">
                      Device Configuration Instructions
                    </Typography>
                  </Link>
                </div>
                <div className={classes.linkContainer}>
                  <Link style={{ textDecoration: 'none', color: '#f0f0f0' }} to='/device/installation'>
                    <Typography className={classes.text} gutterBottom variant="subtitle2" component="h1" color="primary" align="left">
                      Device Installation Instructions
                    </Typography>
                  </Link>
                </div>
                <div style={{ height: '10px' }} />
              </div>
            </Card>
            <Card className={classes.card} raised='false'>
              <div className={classes.topicContainer}>
                <div style={{ height: '20px' }} />
                <div className={classes.indexContainer}>
                  <Typography className={classes.indexText} gutterBottom variant="h5" component="h5" color="secondary" align="left">
                    F
                  </Typography>
                </div>
                <div className={classes.linkContainer}>
                  <Link style={{ textDecoration: 'none', color: '#f0f0f0' }} to='/faq'>
                    <Typography className={classes.text} gutterBottom variant="subtitle2" component="h1" color="primary" align="left">
                      FAQs
                    </Typography>
                  </Link>
                </div>
                <div style={{ height: '10px' }} />
              </div>
            </Card>
            <Card className={classes.card} raised='false'>
              <div className={classes.topicContainer}>
                <div style={{ height: '20px' }} />
                <div className={classes.indexContainer}>
                  <Typography className={classes.indexText} gutterBottom variant="h5" component="h5" color="secondary" align="left">
                    M
                  </Typography>
                </div>
                <div className={classes.linkContainer}>
                  <Link style={{ textDecoration: 'none', color: '#f0f0f0' }} to='/mobileapp'>
                    <Typography className={classes.text} gutterBottom variant="subtitle2" component="h1" color="primary" align="left">
                      Mobile App Introductions
                    </Typography>
                  </Link>
                </div>
                <div className={classes.linkContainer}>
                  <Link style={{ textDecoration: 'none', color: '#f0f0f0' }} to='/product'>
                    <Typography className={classes.text} gutterBottom variant="subtitle2" component="h1" color="primary" align="left">
                      MoniThing Device
                    </Typography>
                  </Link>
                </div>
                <div style={{ height: '10px' }} />
              </div>
            </Card>
            <Card className={classes.card} raised='false'>
              <div className={classes.topicContainer}>
                <div style={{ height: '20px' }} />
                <div className={classes.indexContainer}>
                  <Typography className={classes.indexText} gutterBottom variant="h5" component="h5" color="secondary" align="left">
                    P
                  </Typography>
                </div>
                <div className={classes.linkContainer}>
                  <Link style={{ textDecoration: 'none', color: '#f0f0f0' }} to='/privacy'>
                    <Typography className={classes.text} gutterBottom variant="subtitle2" component="h1" color="primary" align="left">
                      Privacy Policy
                    </Typography>
                  </Link>
                </div>
                <div className={classes.linkContainer}>
                  <Link style={{ textDecoration: 'none', color: '#f0f0f0' }} to='/product'>
                    <Typography className={classes.text} gutterBottom variant="subtitle2" component="h1" color="primary" align="left">
                      Product Information
                    </Typography>
                  </Link>
                </div>
                <div style={{ height: '10px' }} />
              </div>
            </Card>
            <Card className={classes.card} raised='false'>
              <div className={classes.topicContainer}>
                <div style={{ height: '20px' }} />
                <div className={classes.indexContainer}>
                  <Typography className={classes.indexText} gutterBottom variant="h5" component="h5" color="secondary" align="left">
                    S
                  </Typography>
                </div>
                <div className={classes.linkContainer}>
                  <Link style={{ textDecoration: 'none', color: '#f0f0f0' }} to='/support'>
                    <Typography className={classes.text} gutterBottom variant="subtitle2" component="h1" color="primary" align="left">
                      Support
                    </Typography>
                  </Link>
                </div>
                <div style={{ height: '10px' }} />
              </div>
            </Card>
            <Card className={classes.card} raised='false'>
              <div className={classes.topicContainer}>
                <div style={{ height: '20px' }} />
                <div className={classes.indexContainer}>
                  <Typography className={classes.indexText} gutterBottom variant="h5" component="h5" color="secondary" align="left">
                    T
                  </Typography>
                </div>
                <div className={classes.linkContainer}>
                  <Link style={{ textDecoration: 'none', color: '#f0f0f0' }} to='/termofuse'>
                    <Typography className={classes.text} gutterBottom variant="subtitle2" component="h1" color="primary" align="left">
                      Terms of Use
                    </Typography>
                  </Link>
                </div>
                <div style={{ height: '10px' }} />
              </div>
            </Card>
          </MuiThemeProvider>
          <div style={{ width: '100%', height: '20px'}} />
        </div>
        </div>
        <FooterBar/>
      </div>
    );
  }
}

Sitemaps.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Sitemaps);
