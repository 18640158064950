import React from 'react';
var productImages = require.context('../images/product', true);

const flow_images = [
  './arrow1.png',
  './arrow2.png',
  './arrow3.png',
];


class DrawFlow extends React.Component {
  constructor(props) {
    super(props);

    this.downImgs = flow_images.map(function(name, i) {
      return <img
        style={{
          width: '144px',
          height: '37px',
          transform: 'rotate(310deg)',
          position: 'relative',
          top: '240px',
          left: '200px',
        }}
        src={productImages(name)}
        alt=""
        key={i}
      />
    });

    this.upImgs = flow_images.map(function(name, i) {
      return <img
        style={{
          width: '144px',
          height: '37px',
          transform: 'rotate(50deg)',
          position: 'relative',
          top: '220px',
          left: '450px',
        }}
        src={productImages(name)}
        alt=""
        key={i}
      />
    });
  }

  render() {
    return (
      <div>
        <div>
          {this.downImgs[this.props.img]}
        </div>
        <div>
          {this.upImgs[this.props.img]}
        </div>
      </div>
    );
  }
}

export default DrawFlow;
