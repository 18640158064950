import React from 'react';
import classNames from 'classnames';
import 'react-sticky-header/styles.css';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import BuildIcon from '@material-ui/icons/Build';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import { Link } from 'react-router-dom';
import HeaderBar from '../tools/HeaderBar';
import FooterBar from '../tools/FooterBar';
import SideBar from '../tools/SideBar';
import { getMixpanel } from '../tools/MixpanelHelper';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#f0f0f0' },
    secondary: { main: '#FFAA5E' },
    background: { main: '#420000' },
    textPrimary: { main: '#aa0000' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "Arial",
    "fontSize": 16,
    "fontWeightMedium": 900
  }
});

const styles = {
  screenContainer: {
    width: '100wv',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  backgroundContainer: {
    width: "100%",
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
		backgroundColor: '#424242',
  },
  gap: {
    width: '50px',
    height: '60px',
  },
  card: {
    width: '100%',
    borderRadius: 0,
    backgroundColor: '#424242',
    boxShadow: '0 0px 0px 0px',
    padding: '100px',
  },
  topicContainer: {
    width: '100%',
    height: '100%',
    grow: 1,
    flexDirection: 'column',
    justifyContent: 'left',
    margin: 'auto',
    paddingLeft: '20px',
  },
  item: {
    justifyContent: 'left',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  linkButton: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    padding: '6px 12px',
    border: '0px solid',
    backgroundColor: '#424242',
    borderColor: '#424242',
    fontFamily: [
      'sans-serif',
    ].join(','),
    '&:hover': {
      backgroundColor: '#424242',
      borderColor: '#424242',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#424242',
      borderColor: '#424242',
    },
    '&:focus': {
      boxShadow: '0 0 0 0 rgba(0,0,0,0)',
    },
  },
  text: {
    width: '100%',
    flexDirection: 'row',
    paddingLeft: '20px',
  },
};

class Support extends React.Component {
  componentWillMount() {
    let mixpanel = getMixpanel();
    mixpanel.track("Support Page.");
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.screenContainer}>
        <Helmet>
            <title>MoniThing Device Technical Support</title>
            <meta name='description' content = 'The MoniThing device technical support imformation' />
        </Helmet>
        <HeaderBar/>
        <div style={styles.backgroundContainer}>
          <SideBar />
          <MuiThemeProvider theme={theme}>
            <Card className={classes.card} raised='false'>
              <div className={classes.topicContainer}>
                <Typography className={classes.text} gutterBottom variant="h7" component="h1"  color="primary" align="left">
                  We can help
                </Typography>
                <div style={{ marginTop: '20px' }} />
                <Link style={{ textDecoration: 'none' }} to='/mobileapp'>
                  <Button size="larger" color="primary" className={classNames(classes.button, classes.linkButton)} disableRipple>
                    <SmartphoneIcon color="secondary" className={classNames(classes.leftIcon)} />
                    Android and iPhone App Introduction
                  </Button>
                </Link>
                <div style={{ marginTop: '20px' }} />
                <Link style={{ textDecoration: 'none' }} to='/device/configuration'>
                  <Button size="larger" color="primary" className={classNames(classes.button, classes.linkButton)} disableRipple>
                    <BuildIcon color="secondary" className={classNames(classes.leftIcon)} />
                    Device Configuration Instructions
                  </Button>
                </Link>
                <div style={{ marginTop: '20px' }} />
                <Link style={{ textDecoration: 'none' }} to='/device/installation'>
                  <Button size="larger" color="primary" className={classNames(classes.button, classes.linkButton)} disableRipple>
                    <SettingsApplicationsIcon color="secondary" className={classNames(classes.leftIcon)} />
                    Device Installation Instructions
                  </Button>
                </Link>
                <div style={{ marginTop: '20px' }} />
                <Link style={{ textDecoration: 'none' }} to='/faq'>
                  <Button size="larger" color="primary" className={classNames(classes.button, classes.linkButton)} disableRipple>
                    <QuestionAnswerIcon color="secondary" className={classNames(classes.leftIcon)} />
                    FAQ
                  </Button>
                </Link>
                <div style={{ marginTop: '80px' }} />
                <Typography className={classes.text} gutterBottom variant="h7" component="h1"  color="primary" align="left">
                  Contact Us
                </Typography>
                <div style={{ marginTop: '20px' }} />
                <Typography className={classes.text} gutterBottom variant="h6" component="h3"  color="primary" align="left">
                  Email: support@monithing.com
                </Typography>
              </div>
            </Card>
          </MuiThemeProvider>
        </div>
        <FooterBar/>
      </div>
    );
  }
}

Support.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Support);
