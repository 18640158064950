import React from 'react';
import 'react-sticky-header/styles.css';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import  { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import HeaderBar from '../tools/HeaderBar';
import FooterBar from '../tools/FooterBar';
import SideBar from '../tools/SideBar';
import DeviceFarRightSideBarImage from '../images/sidebar/device-far-right-sidebar.png';
import { getMixpanel } from '../tools/MixpanelHelper';

var faqImages = require.context('../images/faq', true);

const theme = createMuiTheme({
  palette: {
    primary: { main: '#f0f0f0' },
    secondary: { main: '#FFAA5E' },
    background: { main: '#420000' },
    textPrimary: { main: '#FFAA5E' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "Arial",
    "fontSize": 16,
    "fontWeightMedium": 900
  }
});

const styles = {
  screenContainer: {
    width: "100wv",
  },
  contentContainer: {
    width: "100%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  backgroundContainer: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
		backgroundColor: '#424242',
    margin: 'auto',
  },
  card: {
    width: '850px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    borderRadius: 0,
    backgroundColor: '#424242',
    boxShadow: '0 0px 0px 0px',
  },
  topicContainer: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'left',
    margin: 'auto',
    paddingLeft: '30px',
  },
  qaContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  anwserContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
  },
  anwserSegment: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  qaText: {
    width: '20px',
  },
  questionText: {
    width: '100%',
  },
  text: {
    width: '100%',
    flexDirection: 'row',
    paddingLeft: '20px',
  },
};

const appIamgeStyle = {
  container: {
    width: 260,
    height: 235,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  appContainer: {
    width: 129,
    height: 235,
    display: 'flex',
    flexDirection: 'column',
  },
  iconContainer: {
    width: 129,
    height: 32,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  gap: {
    width: 5,
    height: 230,
  },
}

function getDeviceImages(image) {

  return (
    <div style={appIamgeStyle.container} >
      <Popup
        trigger={
          <img
            style={{ border: '2px solid #aaaaaa'}}
            src={image}
            alt="FAQ"
            height="231"
            width="256"/>
        }
        on="hover"
        position="left center"
        arrow={false}
        closeOnDocumentClick
        offsetY = '100px'
        contentStyle = {{
          width: '554px',
          height:'500px',
          padding: 0,
          border: '0px solid #424242',
        }}
      >
        <img
          style={{ border: '2px solid #aaaaaa'}}
          src={image}
          alt="FAQ"
          height="500"
          width="554"/>
      </Popup>
    </div>
  );
}

class FAQ extends React.Component {
  componentWillMount() {
    let mixpanel = getMixpanel();
    mixpanel.track("FAQ Page.");
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.screenContainer}>
        <Helmet>
            <title>MoniThing Device FAQ</title>
            <meta name='description' content = 'The MoniThing device FAQs' />
        </Helmet>
        <HeaderBar/>
        <div className={classes.contentContainer}>
          <SideBar image = {DeviceFarRightSideBarImage} />
          <div style={styles.backgroundContainer}>
            <MuiThemeProvider theme={theme}>
              <Card className={classes.card} raised='false'>
                <div className={classes.topicContainer}>
                  <div style={{ height: '20px' }} />
                  <Typography className={classes.text} gutterBottom variant="h6" component="h1" color="primary" align="left">
                    Frequently Asked Questions
                  </Typography>
                  <div style={{ height: '20px' }} />
                </div>
              </Card>
              <Card className={classes.card} raised='false'>
                <div className={classes.topicContainer}>
                  <div style={{ height: '20px' }} />
                  <div className={classes.qaContainer}>
                    <Typography className={classes.qaText} gutterBottom variant="h7" component="h3" color="secondary" align="left">
                      Q:
                    </Typography>
                    <div style={{ width: '10px' }} />
                    <Typography className={classes.questionText} gutterBottom variant="h7" component="h3" color="secondary" align="left">
                      What should I do after I receive the alert message "The battery inside your '***' device is getting too low.
                      Please charge it before it stops working."?
                    </Typography>
                  </div>
                  <div style={{ height: '20px' }} />
                  <div className={classes.qaContainer}>
                    <Typography className={classes.qaText} gutterBottom variant="h7" component="h3" color="primary" align="left">
                      A:
                    </Typography>
                    <div style={{ width: '10px' }} />
                    <div className={classes.anwserContainer}>
                      <div className={classes.anwserSegment}>
                        <Typography className={classes.questionText} gutterBottom variant="subtitle2" color="primary" align="left">
                          If you see this alert text message, the battery inside the MoniThing device
                          needs to be recharged. First, you need to locate the micro UBS port on the
                          device as the picture shows on the right.
                        </Typography>
                        <div style={{ width: '10px' }} />
                        {getDeviceImages(faqImages(`./device-usb.png`))}
                      </div>
                      <div style={{ height: '10px' }} />
                      <div className={classes.anwserSegment}>
                        <Typography className={classes.questionText} gutterBottom variant="subtitle2" color="primary" align="left">
                          Please pay attention to the shape of the USB port opening when inserting
                          the USB connector. After the USB cable is inserted into the device correctly,
                          plug the charger into an electrical outlet. You should see the red LED light
                          during charging. The LED light turns green when the battery is fully charged.
                          The charging time usually takes about 2 to 3 hours.
                        </Typography>
                        <div style={{ width: '10px' }} />
                        {getDeviceImages(faqImages(`./device-charging.png`))}
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '40px' }} />
                </div>
              </Card>
              <Card className={classes.card} raised='false'>
                <div className={classes.topicContainer}>
                  <div style={{ height: '20px' }} />
                  <div className={classes.qaContainer}>
                    <Typography className={classes.qaText} gutterBottom variant="h7" component="h3" color="secondary" align="left">
                      Q:
                    </Typography>
                    <div style={{ width: '10px' }} />
                    <Typography className={classes.questionText} gutterBottom variant="h7" component="h3" color="secondary" align="left">
                      How do I reset the MoniThing device to the Factory Settings state?
                    </Typography>
                  </div>
                  <div style={{ height: '20px' }} />
                  <div className={classes.qaContainer}>
                    <Typography className={classes.qaText} gutterBottom variant="h7" component="h3" color="primary" align="left">
                      A:
                    </Typography>
                    <div style={{ width: '10px' }} />
                    <div className={classes.anwserContainer}>
                      <div className={classes.anwserSegment}>
                        <Typography className={classes.questionText} gutterBottom variant="subtitle2" color="primary" align="left">
                          In order to reset the MoniThing device to the factory settings,
                          you need to locate the pin hole on the device. There is a button
                          hidden in the pin hole.
                        </Typography>
                        <div style={{ width: '10px' }} />
                        {getDeviceImages(faqImages(`./device-pin.png`))}
                      </div>
                      <div style={{ height: '10px' }} />
                      <div className={classes.anwserSegment}>
                        <Typography className={classes.questionText} gutterBottom variant="subtitle2" color="primary" align="left">
                          Push a paperclip into the pin hole gently and hold for about 15
                          seconds. The device is switched to the factory setting state if
                          the red light stays on after the paperclip is pulled out. Try to
                          hold it for longer if the red light turns off. The device turns
                          itself off after about 10 minutes if nobody tries to configure
                          it. Please follow{' '}
                          <Link style={{ textDecoration: 'none', color: 'lightgreen' }} to='/device/configuration'>
                            "Device Configuration Instructions"
                          </Link>
                          {' '}to reconfigure the device.
                        </Typography>
                        <div style={{ width: '10px' }} />
                        {getDeviceImages(faqImages(`./device-reset.png`))}
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '40px' }} />
                </div>
              </Card>
              <Card className={classes.card} raised='false'>
                <div className={classes.topicContainer}>
                  <div style={{ height: '20px' }} />
                  <div className={classes.qaContainer}>
                    <Typography className={classes.qaText} gutterBottom variant="h7" component="h3" color="secondary" align="left">
                      Q:
                    </Typography>
                    <div style={{ width: '10px' }} />
                    <Typography className={classes.questionText} gutterBottom variant="subtitle2" color="secondary" align="left">
                      What should I do when the WiFi password changes or if I
                      upgraded to a new router?
                    </Typography>
                  </div>
                  <div style={{ height: '20px' }} />
                  <div className={classes.qaContainer}>
                    <Typography className={classes.qaText} gutterBottom variant="h7" component="h3" color="primary" align="left">
                      A:
                    </Typography>
                    <div style={{ width: '10px' }} />
                    <div className={classes.anwserContainer}>
                      <div className={classes.anwserSegment}>
                        <Typography className={classes.questionText} gutterBottom variant="subtitle2" color="primary" align="left">
                          The MoniThing device will need to be configured again in order
                          to regain the WiFi connection. The first step is to reset the
                          device back to the factory settings. Please follow the steps
                          mentioned in the previous question to reset the device.
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '40px' }} />
                </div>
              </Card>
              <Card className={classes.card} raised='false'>
                <div className={classes.topicContainer}>
                  <div style={{ height: '20px' }} />
                  <div className={classes.qaContainer}>
                    <Typography className={classes.qaText} gutterBottom variant="h7" component="h3" color="secondary" align="left">
                      Q:
                    </Typography>
                    <div style={{ width: '10px' }} />
                    <Typography className={classes.questionText} gutterBottom variant="h7" component="h3" color="secondary" align="left">
                      How do I find the water flow direction from MoniThing device?
                    </Typography>
                  </div>
                  <div style={{ height: '20px' }} />
                  <div className={classes.qaContainer}>
                    <Typography className={classes.qaText} gutterBottom variant="h7" component="h3" color="primary" align="left">
                      A:
                    </Typography>
                    <div style={{ width: '10px' }} />
                    <div className={classes.anwserContainer}>
                      <div className={classes.anwserSegment}>
                        <Typography className={classes.questionText} gutterBottom variant="subtitle2" color="primary" align="left">
                          There is a water flow indicator arrow on both sides of the
                          MoniThing device. Please make sure the water flow follows
                          the direction of the arrows.
                        </Typography>
                        <div style={{ width: '10px' }} />
                        {getDeviceImages(faqImages(`./water-flow.png`))}
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '40px' }} />
                </div>
              </Card>
              <Card className={classes.card} raised='false'>
                <div className={classes.topicContainer}>
                  <div style={{ height: '20px' }} />
                  <div className={classes.qaContainer}>
                    <Typography className={classes.qaText} gutterBottom variant="h7" component="h3" color="secondary" align="left">
                      Q:
                    </Typography>
                    <div style={{ width: '10px' }} />
                    <Typography className={classes.questionText} gutterBottom variant="h7" component="h3" color="secondary" align="left">
                      How do I know which state my Monithing device is in?
                    </Typography>
                  </div>
                  <div style={{ height: '20px' }} />
                  <div className={classes.qaContainer}>
                    <Typography className={classes.qaText} gutterBottom variant="h7" component="h3" color="primary" align="left">
                      A:
                    </Typography>
                    <div style={{ width: '10px' }} />
                    <div className={classes.anwserContainer}>
                      <div className={classes.anwserSegment}>
                        <Typography className={classes.questionText} gutterBottom variant="subtitle2" color="primary" align="left">
                          The MoniThing device can be in one of two states, <b>"Factory
                          Setting"</b> state and <b>"User Configured"</b> state. To find
                          out which state your device is in, you use a paperclip to push
                          the button in the pin hole and pay attention to the LED light.
                          It is in <b>"User Configured"</b> state if the LED light turns
                          off immediately after you release the button. If the LED light
                          stays on, your device is in <b>"Factory Setting"</b> state.
                          The device turns itself off after about 10 minutes.
                        </Typography>
                        <div style={{ width: '10px' }} />
                        {getDeviceImages(faqImages(`./device-reset.png`))}
                      </div>
                    </div>
                  </div>
                  <div style={{ height: '40px' }} />
                </div>
              </Card>
            </MuiThemeProvider>
            <div style={{ width: '100%', height: '200px'}} />
          </div>
        </div>
        <FooterBar/>
      </div>
    );
  }
}

FAQ.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FAQ);
