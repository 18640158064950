import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import DeviceLeftSideBarImage from '../images/sidebar/device-left-sidebar.png';

const styles = {
  barContainer: {
    width: '250px',
    flexGrow: 1,
    backgroundColor: 'transparent',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top left",
    backgroundSize: "250px 1080px",
    backgroundAttachment: "fixed",
  },
  sideBar: {
    width: '250px',
    backgroundColor: 'transparent',
    boxShadow: '0 0px 0px 0px',
  },
};

function SideBar(props) {
  const { classes, image } = props;
  let image_path = DeviceLeftSideBarImage;
  if (image !== undefined) {
    image_path = image;
  }
  return (
    <div className={classes.barContainer} style={{backgroundImage: `url(${image_path})`}} >
      <AppBar className={classes.sideBar} position="static">
      </AppBar>
    </div>
  );
}

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SideBar);
