import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import { Helmet } from "react-helmet";
import DeviceFullImage from '../images/front/device-fullpage.jpg';
import IntroCard from '../cards/IntroCard';
import MessageCard from '../cards/MessageCard';
import FooterBar from '../tools/FooterBar';
import { getMixpanel } from '../tools/MixpanelHelper';

const styles = {
  backgroundContainer: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: `url(${DeviceFullImage})`,
    backgroundPosition: 'center center',
		backgroundRepeat:  'no-repeat',
		backgroundAttachment: 'fixed',
		backgroundSize:  'cover',
		backgroundColor: '#e6e6e6'
  },
};

class FrontPage extends Component {
  componentWillMount() {
    let mixpanel = getMixpanel();
    mixpanel.track("Front Page.");
  }

  render() {
    return (
      <div>
        <Helmet>
            <title>MoniThing</title>
            <meta name="description" content="MoniThing Company" />
        </Helmet>
        <Paper style={styles.backgroundContainer} square='true' elevation={0}>
          <IntroCard />
          <div style={{ width: '100%', height: '30px'}} />
          <MessageCard />
          <div style={{ width: '100%', height: '90vh'}} />
          <FooterBar />
        </Paper>
      </div>
    );
  }
}

export default FrontPage;
