import React from 'react';
import 'react-sticky-header/styles.css';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import HeaderBar from '../tools/HeaderBar';
import FooterBar from '../tools/FooterBar';
import SideBar from '../tools/SideBar';
import AppIntroduction from '../steps/AppIntroduction';
import PhoneAppSideBarImage from '../images/sidebar/phoneapp-sidebar.png';
import { getMixpanel } from '../tools/MixpanelHelper';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#f0f0f0' },
    secondary: { main: '#6d6d6d' },
    background: { main: '#420000' },
    textPrimary: { main: '#aa0000' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "Arial",
    "fontSize": 16,
    "fontWeightMedium": 900
  }
});

const styles = {
  screenContainer: {
    width: '100wv',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    width: "100%",
    height: "100%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  backgroundContainer: {
    width: "100%",
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
		backgroundColor: '#424242',
  },
  gap: {
    width: '50px',
    height: '60px',
  },
  card: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    borderRadius: 0,
    backgroundColor: '#424242',
    boxShadow: '0 0px 0px 0px',
    margin: 'auto',
  },
  topicContainer: {
    width: '100%',
    height: '100%',
    grow: 1,
    flexDirection: 'column',
    justifyContent: 'left',
    margin: 'auto',
    paddingTop: '30px',
    paddingLeft: '60px',
  },
  item: {
    justifyContent: 'left',
  },
  button: {
    margin: theme.spacing.unit,
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  linkButton: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    padding: '6px 12px',
    border: '0px solid',
    backgroundColor: '#424242',
    borderColor: '#424242',
    fontFamily: [
      'sans-serif',
    ].join(','),
    '&:hover': {
      backgroundColor: '#424242',
      borderColor: '#424242',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#424242',
      borderColor: '#424242',
    },
    '&:focus': {
      boxShadow: '0 0 0 0 rgba(0,0,0,0)',
    },
  },
  text: {
    width: '100%',
    flexDirection: 'row',
    paddingLeft: '20px',
  },
};

class PhoneApp extends React.Component {
  componentWillMount() {
    let mixpanel = getMixpanel();
    mixpanel.track("Mobile App Page.");
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.screenContainer}>
        <Helmet>
            <title>The Introduction of MoniThing Mobile App</title>
            <meta name='description' content = 'The MoniThing mobile app introduction' />
        </Helmet>
        <HeaderBar/>
        <div className={classes.contentContainer}>
          <SideBar image = {PhoneAppSideBarImage} />
          <Paper style={styles.backgroundContainer} square='true' elevation={0}>
            <MuiThemeProvider theme={theme}>
              <Card className={classes.card} raised='false'>
                <div className={classes.topicContainer}>
                  <Typography className={classes.text} gutterBottom variant="h6" component="h3"  color="primary" align="left">
                    The Introduction of MoniThing App
                  </Typography>
                  <div style={{ marginTop: '20px' }} />
                  <AppIntroduction />
                </div>
              </Card>
            </MuiThemeProvider>
          </Paper>
        </div>
        <FooterBar/>
      </div>
    );
  }
}

PhoneApp.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PhoneApp);
