import React from 'react';
import 'react-sticky-header/styles.css';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import HeaderBar from '../tools/HeaderBar';
import FooterBar from '../tools/FooterBar';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#f0f0f0' },
    secondary: { main: '#0EFF04' },
    background: { main: '#420000' },
    textPrimary: { main: '#aa0000' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "Arial",
    "fontSize": 16,
    "fontWeightMedium": 900
  }
});

const styles = {
  screenContainer: {
    width: '100wv',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  card: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    borderRadius: 0,
    backgroundColor: '#424242',
    boxShadow: '0 0px 0px 0px',
  },
  text: {
    width: '100%',
    margin: 'auto',
  },
};

class PackageLanding1 extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.screenContainer}>
        <Helmet>
          <title>MoniThing Product Cart</title>
          <meta name='description' content = 'MoniThing Product Cart' />
          <meta name='robots' content = 'noindex,nofollow' />
        </Helmet>
        <HeaderBar/>
        <MuiThemeProvider theme={theme}>
          <Card className={classes.card} raised='false'>
            <div style={{height: '100px', }} />
            <Typography className={classes.text} variant="h4" component="h1" color="primary" align="center">
              The product is coming soon ...
            </Typography>
            <div style={{height: '800px', }} />
          </Card>
        </MuiThemeProvider>
        <FooterBar/>
      </div>
    );
  }
}

PackageLanding1.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PackageLanding1);
