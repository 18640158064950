import React from 'react';
import 'react-sticky-header/styles.css';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import HeaderBar from '../tools/HeaderBar';
import { getMixpanel } from '../tools/MixpanelHelper';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#f0f0f0' },
    secondary: { main: '#6d6d6d' },
    background: { main: '#420000' },
    textPrimary: { main: '#aa0000' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "Arial",
    "fontSize": 16,
    "fontWeightMedium": 900
  }
});

const styles = {
  screenContainer: {
    width: '100wv',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#424242',
  },
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 0,
    backgroundColor: '#424242',
    boxShadow: '0 0px 0px 0px',
  },
  topicContainer: {
    width: '95%',
    height: '90%',
    backgroundColor: '#f0f0f0',
    margin: 'auto',
  },
};

class TermOfUse extends React.Component {
  componentWillMount() {
    let mixpanel = getMixpanel();
    mixpanel.track("Terms of Use Page.");
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.screenContainer}>
        <Helmet>
            <title>MoniThing Terms of Use</title>
            <meta name='description' content = 'MoniThing Terms of Use' />
            <meta name='robots' content = 'noindex,nofollow' />
        </Helmet>
        <HeaderBar/>
        <MuiThemeProvider theme={theme}>
          <Card className={classes.card} raised='false'>
            <div className={classes.topicContainer}>
              <iframe
                src="http://www.monithing.com/terms-of-use.html"
                title='Terms of Use'
                height='100%'
                width='100%'
              />
            </div>
          </Card>
        </MuiThemeProvider>
      </div>
    );
  }
}

TermOfUse.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TermOfUse);
