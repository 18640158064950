import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import logoIcon from '../images/logo/logo-icon.png';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#f0f0f0' },
    secondary: { main: '#aaaaaa' },
    background: { main: '#424242' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
    "fontSize": 20,
    "fontWeightMedium": 900
  }
});

const styles  = {
  appBarContainer: {
    width: '100%',
    height: '60px',
    backgroundColor: '#6d6d6d',
    boxShadow: '0 0px 0px 0px',
  },
  toolContainer: {
    width: '1200px',
    height: '60px',
    margin: 'auto',
    backgroundColor: '#6d6d6d',
  },
  grow: {
    flexGrow: 1,
  },
  logoButton: {
    marginRight: 20,
  },
  textButton: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    padding: '6px 12px',
    border: '0px solid',
    backgroundColor: '#6d6d6d',
    borderColor: '#6d6d6d',
    fontFamily: [
      'sans-serif',
    ].join(','),
    '&:hover': {
      backgroundColor: '#6d6d6d',
      borderColor: '#6d6d6d',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#6d6d6d',
      borderColor: '#6d6d6d',
    },
    '&:focus': {
      boxShadow: '0 0 0 0 rgba(0,0,0,0)',
    },
  },
};

function HeaderBar(props) {
  const { classes } = props;
  return (
    <div className={classes.appBarContainer}>
      <MuiThemeProvider theme={theme}>
        <AppBar className={classes.appBarContainer}  position="fixed">
          <div className={classes.toolContainer}>
            <Toolbar>
              <Link style={{ textDecoration: 'none' }} to='/'>
                <Button
                  color="primary"
                  disableRipple
                  className={classNames(classes.textButton)}
                >
                  <img className={classes.logoButton} src={logoIcon} alt="Logo" height="32" width="32" />
                </Button>
              </Link>
              <div className={classes.grow} />
              <Link style={{ textDecoration: 'none' }} to='/product'>
                <Button
                  color="primary"
                  disableRipple
                  className={classNames(classes.textButton)}
                >
                  Product
                </Button>
              </Link>
              <div className={classes.grow} />
              <Link style={{ textDecoration: 'none' }} to='/support'>
                <Button
                  color="primary"
                  disableRipple
                  className={classNames(classes.textButton)}
                >
                  Support
                </Button>
              </Link>
            </Toolbar>
          </div>
        </AppBar>
      </MuiThemeProvider>
    </div>
  );
}

HeaderBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HeaderBar);
