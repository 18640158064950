import React from 'react';
import 'react-sticky-header/styles.css';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from "react-helmet";
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import HeaderBar from '../tools/HeaderBar';
import FooterBar from '../tools/FooterBar';
import SideBar from '../tools/SideBar';
import ConfigurationInstructions from '../steps/ConfigurationInstructions';
import DeviceMiddleSideBarImage from '../images/sidebar/device-middle-sidebar.png';
import { getMixpanel } from '../tools/MixpanelHelper';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#f0f0f0' },
    secondary: { main: '#6d6d6d' },
    background: { main: '#420000' },
    textPrimary: { main: '#aa0000' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "Arial",
    "fontSize": 16,
    "fontWeightMedium": 900
  }
});

const styles = {
  screenContainer: {
    width: "100wv",
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    width: "100%",
    height: "100%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  backgroundContainer: {
    width: "100%",
    height: "100%",
		backgroundColor: '#424242',
  },
  card: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    borderRadius: 0,
    backgroundColor: '#424242',
    boxShadow: '0 0px 0px 0px',
    margin: 'auto',
  },
  topicContainer: {
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'left',
    margin: 'auto',
    paddingLeft: '30px',
  },
  text: {
    width: '100%',
    flexDirection: 'row',
    paddingLeft: '20px',
  },
};

class Configuration extends React.Component {
  componentWillMount() {
    let mixpanel = getMixpanel();
    mixpanel.track("Configuration Page.");
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.screenContainer}>
        <Helmet>
            <title>MoniThing Device Configuration Instructions</title>
            <meta name='description' content = 'The MoniThing device configuration instructions' />
        </Helmet>
        <HeaderBar/>
        <div className={classes.contentContainer}>
          <SideBar image = {DeviceMiddleSideBarImage} />
          <Paper style={styles.backgroundContainer} square='true' elevation={0}>
            <MuiThemeProvider theme={theme}>
              <Card className={classes.card} raised='false'>
                <div className={classes.topicContainer}>
                  <div style={{ marginTop: '20px' }} />
                  <Typography className={classes.text} gutterBottom variant="h6" component="h3"  color="primary" align="left">
                    Device Configuration Instructions
                  </Typography>
                  <div style={{ marginTop: '40px' }} />
                  <ConfigurationInstructions />
                </div>
              </Card>
            </MuiThemeProvider>
          </Paper>
        </div>
        <FooterBar />
      </div>
    );
  }
}

Configuration.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Configuration);
