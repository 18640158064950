import React, { Component } from 'react';
import 'react-sticky-header/styles.css';
import HeaderBar from '../tools/HeaderBar';
import FrontPage from '../frontpage/FrontPage';

class Home extends Component {
  render() {
    return (
      <div>
        <HeaderBar/>
        <FrontPage />
      </div>
    );
  }
}

export default Home;
