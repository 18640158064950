import React from 'react';
import 'react-sticky-header/styles.css';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { Link } from 'react-router-dom';
import StopSignImage from '../images/install/stop-sign.png';
import './colors.css';
var images = require.context('../images/install', true);

const theme = createMuiTheme({
  palette: {
    primary: { main: '#FFAA5E' },
    secondary: { main: '#f0f0f0' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "Arial",
    "fontSize": 18,
    "fontWeightRegular": 300,
    "fontWeightMedium": 400,
  },
  stepper: {
      iconColor: '#aa0000',
  },
  overrides: {
    MuiStepLabel: {
      label: {
        color: '#f0f0f0',
        '&$active': {
          color: '#FFAA5E',
        },
      },
    },
  },
});

const styles = theme => ({
  backgroundContainer: {
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    color: '#f0f0f0',
		backgroundColor: '#424242',
  },
  contentContainer: {
    width: "100%",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  textContainer: {
    width: '600px',
    paddingRight: '20px',
  },
  text: {
    width: '300px',
    color: '#f0f0f0',
    marginLeft: '40px',
  },
  root: {
    background: '#424242',
    borderRadius: 0,
    border: 0,
    boxShadow: '0 0 0 0 rgba(255, 105, 135, .3)',
  },
  step: {
    color: "#aaaaaa",
    '&:completed': {
      color: "#aa0000"
    },
    '&:active': {
      color: "#aa0000"
    },
    '&:disabled': {
      color: "#aa0000"
    }
  },
  active: {
  }, //needed so that the &$active tag works
  completed: {
  },
  disabled: {
  },
  labelContainer: {
    color: "#f0f0f0",
  },
});

function getSteps() {
  return [
    'Important Message',
    'Locate the water shut off valve',
    'Shut off water',
    'Flush the toilet',
    'Make sure there is no water running',
    'Place a small container to collect water',
    'Remove the existing toilet connector',
    'Install the device',
    'Turn on water',
    'Check any leakage',
    'Congratulations!',
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return  (
                <div style={{width: '600px', }}>
                  The existing toilet connector needs to be replaced with the
                  MoniThing device as shown in the picture. If you haven't
                  configured the device, it will be easier if you do it before
                  installing the device. Please click{' '}
                  <Link style={{ textDecoration: 'none', color: 'lightgreen' }} to='/device/configuration'>
                    "Device Configuration Instructions"
                  </Link>
                   {' '} and follow the instructions to configure the device.
                  <br/><br/>
                  <b class="redtext">Important:</b><br/>
                  If you don't feel comfortable to work on the basic plumbing tasks,
                  please stop
                  {' '}<img src={StopSignImage} alt='STOP'/>{' '}
                  here and find a plumber to help you do the installation.
                </div>
              );
    case 1:
      return  (
                <div style={{width: '600px', }}>
                  You should find a water shut off valve on the wall behind your
                  toilet or on the floor near your toilet. If you can't find the
                  valve, the MoniThing device might not work for you.
                </div>
              );
    case 2:
      return  (
                <div style={{width: '600px', }}>
                  Shut off the water by turning the valve clockwise until it stops.
                </div>
              );
    case 3:
      return  (
                <div style={{width: '600px', }}>
                  Flush your toilet to release the water from the water tank to
                  prevent any major water spill.
                </div>
              );
    case 4:
      return  (
                <div style={{width: '600px', }}>
                  After the flush is completed, please make sure the water tank is
                  not refilled again. You can listen for the water flow sound or
                  open the water tank lid to check the water level. If you can't
                  completely stop the water flow, please stop
                  {' '}<img src={StopSignImage}  alt='STOP'/>{' '}
                  here until you can stop the water flow.
                </div>
              );
    case 5:
      return  (
                <div style={{width: '600px', }}>
                  There is a small amount of water remaining inside the toilet
                  connector. To avoid water spilling on the floor, please place a
                  small container to collect the water during the disassembly of
                  the toilet connector.
                </div>
              );
    case 6:
      return  (
                <div style={{width: '600px', }}>
                  Locate the toilet connector between the toilet water tank and
                  the water shut off valve. Use an adjustable wrench or pliers
                  to gently loosen and disconnect both ends of the toilet
                  connector.
                </div>
              );
    case 7:
      return  (
                <div style={{width: '600px', }}>
                  There are two different standard-size connectors on the device.
                  The larger end (ballock connector) goes to the toilet water
                  tank. The smaller end (3/8 compression connector) goes to the
                  valve. In case your valve has a 1/2 compression connector, you
                  need to buy an adapter in the hardware store before you can
                  continue. Use an adjustable wrench or pliers to gently fasten
                  both ends of the device.<br/><br/>
                  <b class="redtext">Important:</b>
                  <br/>There is a water flow indicator on the device. Please make
                  sure the device is installed in the right direction.
                </div>
              );
    case 8:
      return  (
                <div style={{width: '600px', }}>
                  Turn on the water by turning the shut off valve counterclockwise
                  until it stops.<br/><br/>
                  <b class="redtext">Important:</b>
                  <br/>Please keep an eye on the device and the connectors. If you
                  see any water leakage, please turn off the water immediately.
                  Turn on the water again after you fix the water leakage.
                </div>
              );
    case 9:
      return  (
                <div style={{width: '600px', paddingRight: '20px', }}>
                  After you turn on the water, please check for any leakage
                  periodically within next 24 hours and fix the leakage if there
                  is any.
                </div>
              );
    case 10:
      return  (
                <div style={{width: '770px', paddingRight: '20px', }}>
                  The device has been installed successfully.<br/><br/>
                  If the device has been configured successfully, everytime you
                  flush the toilet, the LED indicator on the device should light
                  up and the device should turn itself off within 10 seconds
                  after the water flow has stopped. <br/><br/>
                  Now the device will keep an eye on every flush for you and will
                  alert you if any abnormal water flow is detected. Please use
                  the MoniThing mobile app to see your flush data. Thank you for
                  choosing the MoniThing product!
                </div>
              );
    default:
      return  (
                <div style={{width: '600px', paddingRight: '20px', }}>
                  Unknown step
                </div>
              );
  }
}

class InstallationInstructions extends React.Component {
  render() {
    const { classes } = this.props;
    const steps = getSteps();

    return (
      <div className={classes.backgroundContainer}>
        <MuiThemeProvider theme={theme}>
          <Stepper classes={{ root: classes.root }} orientation="vertical">
            {steps.map((label, index) => {
              const labelProps = {};
              labelProps.icon = <div style={{ backgroundColor: 'green', color: 'white', width: '15px', padding: '2px', textAlign: 'center', height: '15px', fontSize: '10px', borderRadius: '50%' }}>{index}</div>
              let img_src = null;
              if (index < steps.length - 1) {
                img_src = images(`./install${index}.png`);
              }
              return (
                <Step key={label} active={true} >
                  <StepLabel
                    classes={{
                      label: classes.labelContainer,
                      completed: classes.labelContainer,
                      active: classes.labelContainer,
                    }}
                    StepIconProps={{
                      classes: {
                        root: classes.step,
                        completed: classes.completed,
                        active: classes.active,
                        disabled: classes.disabled
                      }
                    }}
                  >
                    {label}
                  </StepLabel>
                  <StepContent>
                    <div className={classes.contentContainer} >
                      {(index < steps.length - 1)? <img key = {index} src={img_src} alt="install" height="204" width="150"/> : null}
                      {(index < steps.length - 1)? <div style={{ width: '20px'}} /> : null}
                      <Typography
                        className={styles.text}
                        gutterBottom
                        variant="subtitle2"
                        component="subtitle2"
                        color="secondary"
                        align="justify"
                      >
                        {getStepContent(index)}
                      </Typography>
                    </div>
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
        </MuiThemeProvider>
        <div style={{ width: '100%', height: '300px'}} />
      </div>
    );
  }
}

InstallationInstructions.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(InstallationInstructions);
