import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#f0f0f0' },
    secondary: { main: '#FF4966' },
    background: { main: '#424242' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "Arial",
    "fontSize": 16,
    "fontWeightMedium": 900
  }
});

const styles = {
  contentContainer: {
    width: "100vw",
    backgroundColor: '#424242',
    paddingBottom: '65px',
  },
  card: {
    width: '1200px',
    height: '627',
    borderRadius: 0,
    margin: 'auto',
    paddingTop: '30px',
    backgroundColor: '#424242',
    boxShadow: '0 0px 0px 0px',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: '30px',
    margin: 'auto',
  },
  companyButton: {
    boxShadow: 'none',
    width: '265px',
    height: '55px',
    justifyContent: 'center',
    padding: 0,
    margin: 'auto',
    '&:hover': {
      backgroundColor: '#424242',
      borderColor: '#424242',
    },
  },
  media: {
    width: '100%',
    height: '627',
    marginTop: '40px',
  },
  messageContainer: {
    width: '80%',
    height: '627px',
    margin: 'auto',
  },
  mainMessage: {
    marginTop: '20px',
    fontSize: 34,
  },
  learnMoreButton: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 20,
    padding: '6px 12px',
    border: '0px solid',
    backgroundColor: '#6d6d6d',
    borderColor: '#6d6d6d',
    fontFamily: [
      'sans-serif',
    ].join(','),
    '&:hover': {
      backgroundColor: '#6d6d6d',
      borderColor: '#6d6d6d',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#6d6d6d',
      borderColor: '#6d6d6d',
    },
    '&:focus': {
      boxShadow: '0 0 0 0 rgba(0,0,0,0)',
    },
  },
};

function IntroCard(props) {
  const { classes } = props;
  return (
    <div style={styles.contentContainer}>
      <MuiThemeProvider theme={theme}>
        <Card className={classes.card} raised='false'>
          <div style={styles.buttonContainer}>
            <Link style={{ textDecoration: 'none' }} to='/company'>
              <Button className={classes.companyButton} color="primary">
                <img src={require('../images/logo/logo.png')} alt="Company Logo" />
              </Button>
            </Link>
          </div>
          <CardMedia
            className={classes.media}
            image={require('../images/front/bathroom.png')}
            title="Product Introduction"
          >
            <div style={styles.messageContainer}>
              <CardContent>
                <Typography className={classes.mainMessage} color="textSecondary" gutterBottom align='center' >
                  Let's put our hands together to save our environment!
                </Typography>
              </CardContent>
              <CardActions style={{justifyContent: 'center'}}>
                <Link style={{ textDecoration: 'none' }} to='/product'>
                  <Button variant="contained" color="secondary">
                    Learn More
                  </Button>
                </Link>
              </CardActions>
            </div>
          </CardMedia>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

IntroCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IntroCard);
