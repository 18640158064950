import React from 'react';
import 'react-sticky-header/styles.css';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { getMixpanel } from '../tools/MixpanelHelper';

var Experiment = require("react-ab-test/lib/Experiment");
var Variant = require("react-ab-test/lib/Variant");
var emitter = require("react-ab-test/lib/emitter");

emitter.defineVariants('PriceExperiment', ['OneYear', 'TwoYear', 'FiveYear', 'Unlimited'], [25, 25, 25, 25]);

const theme = createMuiTheme({
  palette: {
    primary: { main: '#f0f0f0' },
    secondary: { main: '#0EFF04' },
    error: { main: '#F53825' },
    textPrimary: { main: '#aa0000' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "Arial",
    "fontSize": 16,
    "fontWeightMedium": 900
  }
});

const styles = {
  screenContainer: {
    width: '200px',
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    width: '100%',
    height: '100%',
    borderRadius: 5,
    backgroundColor: '#6d6d6d',
    boxShadow: '1px 3px 1px #6d6d6d',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
  },
  text: {
    width: '100%',
  }
};


class PricePacakge extends React.Component {
  constructor() {
    super()
    // Good Solution: Bind it in here!
    this.handleClick = this.BuyNowClick.bind(this)
  }

  componentWillMount() {
    emitter.addPlayListener((experimentName, variantName) => {
      let mixpanel = getMixpanel();
      mixpanel.track('Start Experiment', {name: experimentName, variant: variantName});
    })

    emitter.addWinListener((experimentName, variantName) => {
      let mixpanel = getMixpanel();
      mixpanel.track('Winning Experiment', {name: experimentName, variant: variantName});
    })
  }

  BuyNowClick() {
    emitter.emitWin('PriceExperiment');
  }

  abTestRender() {
    const { classes } = this.props;
    return (
      <div>
        <Experiment ref='experiment' name='PriceExperiment'>
          <Variant name='OneYear'>
            <Typography className={classes.text} variant='caption' color='secondary' align='left'>
              <b>
                Package Includes:
                <ul>
                  <li style={{marginLeft: '-25px'}}>One Device</li>
                  <li style={{marginLeft: '-25px'}}>1 Year Subscription</li>
                  <li style={{marginLeft: '-25px'}}>One Year Warranty</li>
                </ul>
              </b>
            </Typography>
            <div style={{height: '10px'}} />
            <Typography className={classes.text} variant='h4' color='primary' align='center'>
              $39.99
            </Typography>
          </Variant>
          <Variant name='TwoYear'>
            <Typography className={classes.text} variant='caption' color='secondary' align='left'>
              <b>
                Package Includes:
                <ul>
                  <li style={{marginLeft: '-25px'}}>One Device</li>
                  <li style={{marginLeft: '-25px'}}>2 Year Subscription</li>
                  <li style={{marginLeft: '-25px'}}>One Year Warranty</li>
                </ul>
              </b>
            </Typography>
            <div style={{height: '10px'}} />
            <Typography className={classes.text} variant='h4' color='primary' align='center'>
              $44.99
            </Typography>
          </Variant>
          <Variant name='FiveYear'>
            <Typography className={classes.text} variant='caption' color='secondary' align='left'>
              <b>
                Package Includes:
                <ul>
                  <li style={{marginLeft: '-25px'}}>One Device</li>
                  <li style={{marginLeft: '-25px'}}>5 Year Subscription</li>
                  <li style={{marginLeft: '-25px'}}>One Year Warranty</li>
                </ul>
              </b>
            </Typography>
            <div style={{height: '10px'}} />
            <Typography className={classes.text} variant='h4' color='primary' align='center'>
              $54.99
            </Typography>
          </Variant>
          <Variant name='Unlimited'>
            <Typography className={classes.text} variant='caption' color='secondary' align='left'>
              <b>
                Package Includes:
                <ul>
                  <li style={{marginLeft: '-25px'}}>One Device</li>
                  <li style={{marginLeft: '-25px'}}>One Year Warranty</li>
                </ul>
              </b>
            </Typography>
            <div style={{height: '10px'}} />
            <Typography className={classes.text} variant='h4' color='primary' align='center'>
              $59.99
            </Typography>
          </Variant>
        </Experiment>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.screenContainer}>
        <MuiThemeProvider theme={theme}>
          <Card className={classes.card} raised='true'>
            <div style={{width: '200px', display: 'flex', flexDirection: 'row', margin: 'auto', }} >
              <div style={{display: 'flex', flexDirection: 'column', margin: 'auto', }} >
                {this.abTestRender()}
                <div style={{height: '100px'}} />
                <div style={{display: 'flex', flexDirection: 'column', margin: 'auto', }} >
                  <Link style={{ textDecoration: 'none' }} to='/pricelanding'>
                    <Button variant="contained" color="secondary" onClick={this.handleClick}>
                      Buy Now
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </Card>
        </MuiThemeProvider>
      </div>
    );
  }
}

PricePacakge.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PricePacakge);
