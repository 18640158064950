import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#f0f0f0' },
    secondary: { main: '#6d6d6d' },
    background: { main: '#420000' },
    textPrimary: { main: '#aa0000' },
  },
  typography: {
    useNextVariants: true,
    "fontFamily": "Arial",
    "fontSize": 16,
    "fontWeightMedium": 900
  }
});

const styles = {
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#424242',
  },
  gap: {
    width: '100%',
    height: '60px',
  },
  card: {
    width: '1100px',
    height: '400px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: 0,
    backgroundColor: '#424242',
    boxShadow: '0 0px 0px 0px',
    margin: 'auto',
  },
  media: {
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#f0f0f0',
    padding: 0,
  },
  text: {
    width: '90%',
    height: '30%',
    flexGrow: 1,
    backgroundColor: '#f0f0f0',
    paddingLeft: '20px',
    paddingRight: '20px',
    margin: 'auto',
  },
};

function MessageCard(props) {
  const { classes } = props;
  return (
    <div style={styles.contentContainer}>
      <MuiThemeProvider theme={theme}>
        <div style={styles.gap} />
        <Card className={classes.card} raised='false'>
          <CardMedia
            className={classes.media}
            image={require('../images/front/savewater.png')}
            title="Limited Fresh Water"
          >
          </CardMedia>
          <CardContent className={classes.media}>
            <Typography className={classes.text} gutterBottom variant="h6" component="h3"  color="secondary" align="left">
              Less than 0.01% of water on our earth can be consumed by human beings
            </Typography>
          </CardContent>
        </Card>
        <Card className={classes.card} raised='false'>
          <CardContent className={classes.media}>
            <Typography className={classes.text} gutterBottom variant="h6" component="h3"  color="secondary" align="right">
              For the sake of our children and our future, we must do more to combat climate change
            </Typography>
          </CardContent>
          <CardMedia
            className={classes.media}
            image={require('../images/front/waterday.png')}
            title="Climate Change"
          >
          </CardMedia>
        </Card>
        <Card className={classes.card} raised='false'>
          <CardMedia
            className={classes.media}
            image={require('../images/front/running-toilet.png')}
            title="Running Toilet"
          >
          </CardMedia>
          <CardContent className={classes.media}>
            <Typography className={classes.text} gutterBottom variant="h6" component="h3"  color="secondary" align="left">
              Let us use the power of cutting-edge technologies to help cut down on water waste.
            </Typography>
          </CardContent>
        </Card>
        <div style={styles.gap} />
      </MuiThemeProvider>
    </div>
  );
}

MessageCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MessageCard);
