import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './index.css';
import Home from './components/Home';
import Company from './components/Company';
import Device from './components/Device';
import Support from './components/Support';
import PhoneApp from './components/PhoneApp';
import Configuration from './components/Configuration';
import Installation from './components/Installation';
import FAQ from './components/FAQ';
import Sitemaps from './components/Sitemaps';
import TermOfUse from './components/TermOfUse';
import Privacy from './components/Privacy';
import PriceLanding from './price/PriceLanding';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Router>
    <Switch>
      <Route exact path= "/" component = {Home} />
      <Route path="/company" component = {Company} />
      <Route path="/product" component = {Device} />
      <Route path="/support" component = {Support} />
      <Route path="/mobileapp" component = {PhoneApp} />
      <Route path="/device/configuration" component = {Configuration} />
      <Route path="/device/installation" component = {Installation} />
      <Route path="/faq" component = {FAQ} />
      <Route path="/sitemaps" component = {Sitemaps} />
      <Route path="/termofuse" component = {TermOfUse} />
      <Route path="/privacy" component = {Privacy} />
      <Route path="/pricelanding" component = {PriceLanding} />
    </Switch>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
